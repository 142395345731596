import React from "react";

const TransparentHeader = ({ headertitle, bgimg, Subheader }) => {
  return (
    <>
      <div
        className="breadcrumb-area"
        style={{ backgroundImage: `url("${bgimg}")` }}
      >
        <div className="text-center relative">
          <h1 className="text-white">{headertitle}</h1>
          {
            headertitle === Subheader ?
              <h6 className="text-white">Home - {Subheader}</h6>
              :
              <h6 className="text-white">{Subheader}</h6>
          }
        </div>
        <div className="flex justify-center ">
          {/* <img
            src="https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/Navidad%2Fluces.gif?alt=media&token=c23d0d87-77f5-4d00-be88-08497f3c4aeb"
            alt="imagens"
            className="relative -top-14 md:-top-20"
          /> */}
        </div>
      </div>
      {/* <div
        className="bg-transparent flex justify-center bg-contain py-5"
        style={{ backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/Navidad%2Fluces-de-navidad-gif-4.gif?alt=media&token=6a881ec1-0704-4fd4-8ce9-e796f1cd3d17")' }}
      >

      </div> */}
    </>
  );
};

export default TransparentHeader;
